import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoginRequest } from '../interfaces/login-request';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { AuthResponse, customToken } from '../interfaces/auth-response';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { RegisterRequest } from '../interfaces/register-request';
import { UserDetail } from '../interfaces/user-detail';
import { Membership } from '../interfaces/membership';
import { SubscribeFree } from '../interfaces/subscribe-free';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl: string = environment.apiUrl;
  private tokenKey = 'token';
  readonly commonService = inject(CommonService);

  constructor(private http: HttpClient) {}

  login(data: LoginRequest): Observable<AuthResponse> {
    return this.http
      .post<AuthResponse>(`${this.apiUrl}account/login`, data)
      .pipe(
        map((response) => {
          if (response.isSuccess) {
            if (typeof localStorage !== 'undefined')
              localStorage.setItem(this.tokenKey, response.token);
            else if (typeof sessionStorage !== 'undefined')
              sessionStorage.setItem(this.tokenKey, response.token);
            else {
              response.message =
                'lo storage del web non è disponibile nel suo sistema';
            }
          }
          return response;
        })
      );
  }

  checkUser(data: LoginRequest): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.apiUrl}account/checkUser`, data);
  } 

  register(data: RegisterRequest): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.apiUrl}account/register`, data);
  }

  modifyUser(data: UserDetail): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(
      `${this.apiUrl}account/modifyUser`,
      data
    );
  }

  deleteUser(data: string): Observable<AuthResponse> {
    return this.http.delete<AuthResponse>(
      `${this.apiUrl}account/deleteuser?id=${data}`
    );
  }

  getDetail = (): Observable<UserDetail> =>
    this.http.get<UserDetail>(`${this.apiUrl}account/detail`);

  getUserDetail = () => {
    const format = 'dd/MM/yyyy';
    const locale = 'it-IT';

    const token = this.getToken();
    if (!token) return null;
    const decodedToken: any = jwtDecode(token);
    const userDetail = {
      id: decodedToken.nameid,
      nome: decodedToken.nome,
      cognome: decodedToken.cognome,
      email: decodedToken.email,
      createdAt: decodedToken.createdAt,
      lastModifyAt: decodedToken.lastModifyAt,
      stato: decodedToken.stato,
      abbonamento: decodedToken.abbonamento,
      abbonamentoTp: decodedToken.abbonamentoTp,
      minorenne: decodedToken.minorenne,
      roles: decodedToken.role || [],
    };

    return userDetail;
  };

  isLoggedIn = () => {
    const token = this.getToken();
    if (!token) return false;
    return !this.isTokenExpired();
  };

  isAttive = (): boolean => {
    const token = this.getToken();
    if (!token) return false;
    const decoded = jwtDecode<customToken>(token);
    return decoded['abbonamento'];
  };

  changePsw(data: any): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(
      `${this.apiUrl}account/changePsw`,
      data
    );
  }

  private isTokenExpired() {
    const token = this.getToken();
    if (!token) return true;
    const decoded = jwtDecode(token);
    const isTokenExpired = Date.now() >= decoded['exp']! * 1000;
    if (isTokenExpired) this.logout();
    return isTokenExpired;
  }

  getRoles = (): string[] | null => {
    const token = this.getToken();
    if (!token) return null;

    const decodedToken: any = jwtDecode(token);
    return decodedToken.role || null;
  };

  logout = (): void => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(this.tokenKey);
    } else if (typeof sessionStorage !== 'undefined') {
      sessionStorage.removeItem(this.tokenKey);
    }
  };

  blockUser(id: any): Observable<AuthResponse> {
    return this.http.post<any>(`${this.apiUrl}account/blockUser`, { id });
  }

  unBlockUser(id: any): Observable<AuthResponse> {
    return this.http.post<any>(`${this.apiUrl}account/unblockUser`, { id });
  }

  getAll = (): Observable<UserDetail[]> =>
    this.http
      .get<UserDetail[]>(`${this.apiUrl}account/getalluser`)
      .pipe(retry({count:3, delay:2000}), catchError(this.handleError));

  getUserById = (id: string, length: boolean = true): Observable<UserDetail> =>
    this.http.get<UserDetail>(
      `${this.apiUrl}account/getUserById?id=${id}&length=${length}`
    );

  getToken = (): string | null =>
    typeof localStorage !== 'undefined'
      ? localStorage.getItem(this.tokenKey)
      : typeof sessionStorage !== 'undefined'
      ? sessionStorage.getItem(this.tokenKey)
      : '';

  createMembership(data: Membership) {
    return this.http.post<AuthResponse>(
      `${this.apiUrl}account/createmembership`,
      data
    );
  }

  createFreeMembership(data: SubscribeFree) {
    return this.http
      .post<AuthResponse>(`${this.apiUrl}account/createfreemembership`, data)
      .pipe(
        map((response) => {
          if (response.isSuccess) {
            if (typeof localStorage !== 'undefined') {
              localStorage.setItem(this.tokenKey, response.token);
            } else if (typeof sessionStorage !== 'undefined') {
              sessionStorage.setItem(this.tokenKey, response.token);
            } else {
              response.message =
                'lo storage del web non è disponibile nel suo sistema';
            }
          }
          return response;
        })
      );
  }

  deleteMembership = (id: string): Observable<{ message: string }> =>
    this.http.delete<{ message: string }>(
      `${this.apiUrl}account/deletemembership?id=${id}`
    );

  modifyMembership(data: Membership): Observable<AuthResponse> {
    return this.http.patch<any>(`${this.apiUrl}account/editAbbonamento`, data);
  }

  getAbbonamento = (ids: string): Observable<Membership> =>
    this.http.get<Membership>(`${this.apiUrl}account/getabbonamento?id=${ids}`);

  getAllAbbonamento = (): Observable<Membership[]> =>
    this.http.get<Membership[]>(`${this.apiUrl}account/getallabbonamento`);

  getAbboNotPayed = (): Observable<any[]> =>
    this.http
      .get<any>(`${this.apiUrl}account/GetAbboNotActive`)
      .pipe(retry({count:3, delay:2000}), catchError(this.handleError));

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('Errore: ', error.error);
    } else {
      console.error(
        `Errore con codice ${error.status}: `,
        error.error.message as AuthResponse
      );
    }

    return throwError(
      () => new Error('Qualcosa è andato storto; Riprovare più tardi')
    );
  }
}
